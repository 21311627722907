.operation-component {
  background-size: cover;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}
.container{
    width: 100%;
}
.price {
  font-size: 20px;
  text-align: start;
  color: #8b2881 !important;
}
.myrow{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.name {
  font-size: 15px;
}

.client {
  font-size: 20px;
  text-align: start;
}

.time {
  font-size: 15px;
}
.circle-container {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

