.payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f8f8;
  padding: 20px;
}

.payment-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.payment-card h2 {
  color: #333;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.success-icon,
.failed-icon {
  font-size: 60px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.success-icon {
  color: white;
  background-color: #4CAF50;
}

.failed-icon {
  color: white;
  background-color: #f44336;
}

.payment-success h3,
.payment-failed h3 {
  margin-bottom: 15px;
  font-size: 24px;
}

.payment-success p,
.payment-failed p {
  margin-bottom: 10px;
  color: #666;
}

.product-info {
  font-weight: bold;
  font-size: 18px;
  margin: 20px 0;
  padding: 15px;
  background-color: #f9f0f9;
  border-radius: 8px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.payment-button {
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: bold;
}

.back-button {
  background-color: #f0f0f0;
  color: #333;
  flex: 1;
}

.back-button:hover {
  background-color: #e0e0e0;
}

.try-again-button {
  background-color: #ff00ff;
  color: white;
  flex: 2;
}

.try-again-button:hover {
  background-color: #d600d6;
}

.payment-initializing {
  padding: 20px;
  color: #666;
}

.payment-processing {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.payment-pulse {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(255, 0, 255, 0.1);
  position: relative;
  animation: pulse 1.5s infinite;
}

.payment-pulse::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ff00ff;
  border-radius: 50%;
  z-index: -1;
  animation: pulse-ring 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.7);
    opacity: 0.3;
  }
  80%, 100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.payment-warning {
  text-align: center;
  padding: 20px 0;
}

.warning-icon {
  font-size: 48px;
  margin-bottom: 15px;
}

.payment-warning h3 {
  color: #e65100;
  margin-bottom: 15px;
}

.payment-warning p {
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Add these RTL styles */

[dir="rtl"] .payment-card {
  text-align: right;
}

[dir="rtl"] .button-group {
  flex-direction: row-reverse;
}