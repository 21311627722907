.product-item {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.product-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.product-item.selected {
  border: 2px solid #ff00ff;
  background-color: #fff9fe;
}

.product-name {
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.product-details {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.product-price {
  font-weight: bold;
  color: #ff00ff;
}

.product-validity {
  color: #666;
  font-size: 0.9em;
}

.selected-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff00ff;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}