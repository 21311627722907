nav  {
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #8b2881;
    animation-name: nav-fot ;
    animation-duration: 1.5s;
    width: 100%;
    margin:  auto;
    border-radius: 10px 10px 0 0;
    position: absolute;
}

@keyframes nav-fot {
    from {height: 50%;z-index: 15;}
    to {height: 5%; position: fixed;}
  }
footer {
    background-color: #8b2881;
    width: 100%;
    margin: auto;
    border-radius: 0 0 10px 10px;
    animation-name: nav-fot ;
    animation-duration: 1.5s;
    position: fixed;
    bottom: 0;
}
#icons{
    display: flex;
    justify-content: space-around;
    width: 70px;}
nav h1 {
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    color: white;
}
i{
    color: white;
    font-size: 1.5em;
    margin: 0;
    padding: 0;
}