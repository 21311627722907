#notFound{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 50%;
    margin: auto;
    font-size: 2em;
    font-weight: bolder;
    color: #b53678;
    font-style: italic;
}