.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.categories-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.category {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.category h2 {
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.picto {
  background-color: #ff00ff;
  color: white;
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
  box-shadow: 0 2px 4px rgba(255, 0, 255, 0.3);
}

.products-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.product-item {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.product-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.product-item h3 {
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.product-item p.product-price,
.product-item .product-price {
  font-size: 1.5rem;
  font-weight: 700;
  color: #ff00ff;
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
}

.product-item .product-validity {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 768px) {
  .products-list {
    grid-template-columns: 1fr;
  }
}

h1 {
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.selected-product-summary {
  background-color: #fff;
  border: 2px solid #ff00ff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.summary-details {
  margin: 15px 0;
}

.summary-details p:nth-child(2) {
  font-size: 1.4rem;
  font-weight: bold;
  color: #ff00ff;
  margin: 15px 0;
}

.purchase-button {
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
  margin-top: 15px;
  box-shadow: 0 4px 8px rgba(255, 0, 255, 0.2);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.purchase-button:hover {
  background-color: #d600d6;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(255, 0, 255, 0.3);
}

.purchase-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(255, 0, 255, 0.3);
}

.product-details-list {
  margin-top: 15px;
  padding: 15px;
  background-color: #f9f0f9;
  border-radius: 8px;
}

.product-details-list h4 {
  margin-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
  color: #222;
}

.detail-item {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

.detail-label {
  font-weight: 600;
  color: #555;
}

/* Add these RTL styles */

[dir="rtl"] .category-header {
  flex-direction: row-reverse;
}

[dir="rtl"] .product-details {
  flex-direction: row-reverse;
}

[dir="rtl"] .detail-item {
  flex-direction: row-reverse;
}

[dir="rtl"] .selected-indicator {
  right: auto;
  left: 10px;
}