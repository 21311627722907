.category {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

.category-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  user-select: none;
}

.category.collapsed .category-header {
  border-bottom: none;
}

.category.expanded .category-header {
  border-bottom: 2px solid #e8e8e8;
  margin-bottom: 15px;
}

.category-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.picto {
  background-color: #ff00ff;
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 14px;
  min-width: 25px;
  text-align: center;
}

.expand-icon {
  color: #555;
  font-size: 14px;
}

.products-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 768px) {
  .products-list {
    grid-template-columns: 1fr;
  }
}