.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
}

.spinner {
  width: 60px;
  height: 60px;
  border: 4px solid rgba(255, 0, 255, 0.1);
  border-radius: 50%;
  border-top: 4px solid #ff00ff;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 15px;
  color: #666;
  font-size: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.progress-indicator {
  position: relative;
  width: 80px;
  height: 80px;
}

.progress-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #ff00ff;
  animation: spin 1.5s linear infinite;
}

.progress-circle:nth-child(2) {
  border-top-color: transparent;
  border-right-color: #ff00ff;
  animation-delay: 0.2s;
}

.progress-circle:nth-child(3) {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #ff00ff;
  animation-delay: 0.4s;
}